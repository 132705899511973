.menu-header {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 2px 4px 8px rgba(0, 0, 0);
    padding: 20px;
}

.subtitle-box {
    display : flex;
    margin-top : 20px;
}

.add-collection {
    background-color: white;
    font-size : 16px;
    padding : 5px;
    border-radius : 3px;
    border : 1px solid #0001;
    margin-left : 25px;
    margin-bottom: 1rem;
    cursor: pointer;
}

.menu-part {
    width: 75%;
    float: right;
    position: relative;
    padding: 0px 0px 10px 0px;
}

.menu-header button {
    background: #202738;
    border-radius: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    padding: 7px 20px 7px 20px;
    margin-right: 20px;
}

.download-upload-section {
    display: flex;
    justify-content: space-between;
    background: rgba(229, 209, 191, 0.12);
    padding: 15px 20px 0px 20px;
}

.download-upload-section p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #323232;
}

.download-upload-section img {
    width: 30%;
    cursor: pointer;
}

.menu-body {
    padding: 20px;
}
.menu-body tr:hover{
    background: rgb(50 50 50 / 13%);
    border-radius: 8px;
  }

.menu-body a {
    color: #323232;
    text-decoration: none;
    font-weight: 500;
}

.menu-body .table>thead {
    background: #F5F1E8;
    border-radius: 8px;
}

.menu-body .table>:not(:first-child) {
    border-top: none !important;
}

.menu-body .table>:not(caption)>*>* {
    border-bottom: none;
}

.delete-modal h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #323232;
    margin-top: 25px;
}

.delete-modal p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: rgba(50, 50, 50, 0.67);
    padding: 10px 60px 10px 60px;
}

.border-none {
    border-top: none !important;
    border-bottom: none !important;
}

.delete-modal-btn {
    background: #202738;
    border: 1px solid #202738;
    border-radius: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 5px 30px 5px 30px;
    margin-right: 20px;
    border: none;
}

.keep-modal-btn {
    background: rgb(192 184 184 / 67%);
    border-radius: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #323232;
    padding: 5px 30px 5px 30px;
    margin-left: 20px;
    border: none;
}

.modal-content {
    border-radius: 1.3rem;
    padding-bottom: 4rem;
}

.uploader {
    display: block;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
}

.uploader label {
    float: left;
    clear: both;
    width: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
    background: #fff;
    border-radius: 7px;
    border: 1px solid rgba(50, 50, 50, 0.2);
    transition: all 0.2s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.image-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #323232;
    margin-top: 21px;
}

/* .uploader label:hover {
    border-color: #454cad;
}

.uploader label.hover {
    border: 3px solid #454cad;
    box-shadow: inset 0 0 0 6px #eee;
}

.uploader label.hover #start i.fa {
    transform: scale(0.8);
    opacity: 0.3;
} */

.uploader #start {
    float: left;
    clear: both;
    width: 100%;
}

.uploader #start.hidden {
    display: none;
}

.uploader #start i.fa {
    font-size: 50px;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out;
}

.uploader #response {
    float: left;
    clear: both;
    width: 100%;
}

.uploader #response.hidden {
    display: none;
}

.uploader #response #messages {
    margin-bottom: 0.5rem;
}

.uploader #file-image {
    display: inline;
    margin: 0 auto 0.5rem auto;
    width: auto;
    height: auto;
    max-width: 180px;
}

.uploader #file-image.hidden {
    display: none;
}

.uploader #notimage {
    display: block;
    float: left;
    clear: both;
    width: 100%;
}

.uploader #notimage.hidden {
    display: none;
}

.uploader input[type=file] {
    display: none;
}

.uploader div {
    margin: 0 0 0.5rem 0;
    color: #5f6982;
}

.uploader .btn {
    display: inline-block;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
    clear: both;
    font-family: inherit;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    text-transform: initial;
    outline: none;
    padding: 1.5rem 2rem 3rem 2rem;
    height: 36px;
    line-height: 36px;
    color: #202738;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    border-color: #454cad;
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid rgba(50, 50, 50, 0.2);
    border-radius: 15px;
}

.upload-section {
    border: 1px dashed rgba(50, 50, 50, 0.67);
    border-radius: 15px;
    text-align: center;
    margin: 30px 40px 10px 40px;
    padding: 20px 10px 20px 10px;
}

.upload-modal-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #323232;
}

.upload-section h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    color: #323232;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}

.custom-file-input:hover::before {
    border-color: black;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.information-modal {
    text-align: center;
}
.information-modal li {
    cursor: pointer;
}

.upload-section p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: rgba(50, 50, 50, 0.67)
}

.upload-section button {
    background: #202738;
    border: 1px solid #202738;
    border-radius: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 3px 20px 3px 20px;
}

.upload-data-tip {
    background: #ADD6A14D !important;
    border-radius: 12px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 21px !important;
    color: #323232 !important;
    padding: 0px 5px 0px 5px !important;
}

.success-modal .modal-content {
    background-color: #202738 !important;
}

.success-modal p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    padding: 20px 20px 20px 20px;
}

.success-modal .modal-content .modal-body {
    margin-top: 30px;
}

.success-modal-btn {
    background: #FFFFFF;
    border-radius: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.25px;
    color: #202738;
}

.menu-information-modal {
    width: 100%;
    float: right;
    margin: 0rem !important;
    max-width: 50% !important;
}

.menu-information-modal .modal-content {
    border-radius: 0px !important;
    height: 100vh;
}

.menu-information-modal .modal-body {
    background-color: #FFFFFF;
}

/* accordion-item css*/
.accordion-button {
    background-color: transparent !important;
}

.border_bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.accordion_switch {
    display: flex;
    justify-content: end;
    position: relative;
    width: 33px;
    height: 8px;
}

.header-button-css {
    padding: 1rem 1.25rem;
}

/* .accordion_switch_inside{
    display: flex;
    justify-content: end;
    position: relative;
    right: 64px;
    top: -39px;
} */

.accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe00 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.menu-information-modal p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #323232;
}

.menu-information-modal .modal-header {
    background: #F5F1E8;
    padding: 2rem 1rem 1rem 1rem;
    height: 35px;
    width: 48.6rem;
    position: fixed;
    z-index: 10;
}

.menu-information-modal .modal-header .btn-close {
    margin: -0.5rem -0.5rem 0.5rem auto !important;
    z-index: 1000;
    position: absolute;
    right: 10px;
}

.modal-content {
    border: 0px !important;
}

.information-modal {
    background: rgba(217, 217, 217, 0.2);
    border-radius: 34px;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
    margin-top: 22px;
}

.information {
    list-style-type: none;
    border-right: 1px solid rgba(50, 50, 50, 0.2);
    /* padding-right: 30px; */
}

.screen-details {
    list-style-type: none;
    border-right: 1px solid rgba(50, 50, 50, 0.2);
    /* padding-right: 30px; */
}

.operation-time {
    list-style: none;
}
.Option{
    list-style: none;
    /* padding-right: 30px; */
    border-right: 1px solid rgba(50, 50, 50, 0.2);
}

.menu-information-image-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #323232;
}

.menu-information label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #323232;
}

.menu-information input {
    background: #FFFFFF;
    border: 1px solid rgba(50, 50, 50, 0.2);
    border-radius: 15px;
}

.menu-select {
    border: 1px solid rgba(50, 50, 50, 0.2);
    border-radius: 15px;
}

.information-description {

    height: 130px !important;
    word-wrap: break-word;
}

.information-btn-save {
    background: #202738;
    border: 1px solid #202738;
    border-radius: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 10px 57px 10px 57px;
    margin-top: 60px;
}

.information-btn-cancel {
    border: 1px solid #202738;
    border-radius: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #202738;
    padding: 10px 50px 10px 50px;
    margin-top: 60px;
}

.operation-section {
    background: rgba(229, 209, 191, 0.1);
    border-radius: 10px;
    padding: 20px;
}

.operation-section h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.25px;
    color: #323232;
}

.accordion-button:not(.collapsed) {
    color: #323232 !important;
    background-color: #F5F1E8 !important;
}

.daily-checkbox-bg {
    background: rgb(198 191 191 / 20%);
    border-right: 1px solid rgba(196, 107, 72, 0.2);
}

.daily-accordian {
    padding: 0rem 0.75rem !important;
}

.menu-mt {
    margin-top: 20px;
}

.menu-mt label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #323232;
}

.menu-mb {
    margin-bottom: 20px;
}

.daily-checkbox-bg p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.25px;
    color: #323232;
    padding: 15px;
}

.accordion-flush .accordion-item {
    border: 1px solid rgba(196, 107, 72, 0.2) !important;
    border-radius: 8px !important;
    margin-bottom: 10px !important;
}

.time-picker-bg {
    background: rgba(229, 209, 191, 0.3);
    padding: 18px 10px 1px 15px;
    margin-top: 30px;
}

.date-input {
    margin: 0px 15px 0px 15px;
}

.date-input input {
    border: 1px solid rgba(196, 107, 72, 0.2) !important;
    border-radius: 8px !important;
    padding-left: 10px;
    padding-right: 10px;
}

.add-section {
    text-align: center;
    border-width: 1px 0px;
    border-style: dashed;
    border-color: rgba(50, 50, 50, 0.67);
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.daily-save {
    background: #202738;
    border: 1px solid #202738;
    border-radius: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    width: 50%;
    padding: 8px;
    margin-left: 10px;
    margin-right: 10px;
}

.daily-cancel {
    border: 1px solid #202738;
    border-radius: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #202738;
    width: 50%;
    padding: 8px;
    margin-left: 10px;
    margin-right: 10px;
    background: #FFFFFF;
}

.react-calendar__navigation button {
    min-width: 34px !important;
}

abbr[data-bs-original-title],
abbr[title] {
    text-decoration: none !important;
}

.date-pera {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 21px !important;
    color: #323232 !important;
}

.calender .react-calendar {
    border: 0.572665px solid rgba(196, 107, 72, 0.2) !important;
    border-radius: 5.41428px !important;
}

.input_bottom input {
    border-radius: 15px;
}

/* bulk-card-css */
.bulk_card {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
}

.card_header {
    background: #EDF1F2;
    border-radius: 15px 15px 0px 0px;
    padding: 11px;
    text-align: center;
}

.bulk_select {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: baseline;
}

.card_header h5 {}

.heading_modal {
    margin-left: 15px;
    padding-top: 12px;
}

.bulk_modal_modal li {
    font-family: poppins;
    line-height: 32px;
}

.bulk_select select {
    /* background: rgba(196, 196, 196, 0.2); */
    border: 1px solid rgba(50, 50, 50, 0.2);
    border-radius: 10px;
    width: 229px;
    height: 34px;
}

.bulk_select p {
    margin-right: 20px;
}

.grid_select {
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;
}



/* breadcrumb-start */
ul.breadcrumb {
    padding: 10px 16px;
    list-style: none;
}

ul.breadcrumb li {
    display: inline;
    font-size: 18px;
    font-family: poppins;
}

ul.breadcrumb li+li:before {
    padding: 8px;
    color: #323232;
    content: "/\00a0";
}

ul.breadcrumb li a {
    color: rgba(50, 50, 50, 0.67);
    text-decoration: none;
    font-family: poppins;
}

.breadcrumb_bg {
    display: flex;
    justify-content: space-between;
    background: rgba(229, 209, 191, 0.12);
    padding-top: 20px;
    padding-right: 22px;
}

.dish-details h6{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #202738;
    text-align: center;
    margin-top: 8rem;
    margin-bottom: 3rem;
    cursor: pointer;
}
