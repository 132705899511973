*{
    font-family: 'Poppins';
}

.heading-css {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    background: #F5F1E8;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 10px 20px 10px 20px;
    margin-bottom: 40px;
}

.heading-css p:nth-child(1) {   
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #323232;
}

.heading-css p:nth-child(2){
    display:flex;
    align-items:center;
}

/*---table CSS---*/

.table-css {
    padding : 3%;
}

.table-border-unset {
    border-collapse: unset;
}

/*Works for centering table cells*/
.cell-vertical-center{
    vertical-align: middle;
}

.cell-vertical-horizontal-center{
    vertical-align: middle;
    text-align: center;
}

/*----------------------*/

/*For flex-direction:row*/
.div-center-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*For flex-direction:column*/
.div-center-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dark-button{
    background: #202738;
    border-radius: 11px;
    padding: 4px 20px;
    color: #fff;
    border: 0px;
}

.remove-bottom-margin{
    margin-bottom:0
}

.cursor-pointer {
    cursor: pointer;
}