.restaurent-table td{
    font-size: 14px;
    width: 200px;
}
.munch_box{
    background: rgba(229, 209, 191, 0.3);
border-radius: 10px;
text-align: center;
padding: 10px;
width: 286px;
margin: 20px 0px;
max-height: 250px;
}
.munch_box a{
    text-decoration: none;
    color: #000;
}
.munch_box img{
    width: 124px;
    height: 124px;
    padding-top: 15px;
    
}
.munch_box h5{
    font-weight: 600;
font-size: 18px;
padding: 20px 0px;

}

.grid-container{
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 10px;
    width: 90%;
}

.tab-config{
    background: rgba(217, 217, 217, 0.2);
    border-radius: 34px;
    justify-content: space-evenly;
    padding: 10px 20px 10px 20px;
    margin-top: 22px;
    list-style: none;
    width: 700px;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
}
.select-event_form{
    height: 54px;
    border-radius: 8px !important;
}
.rest_input{
    height: 54px;
    border-radius: 8px !important;
}

.table-record-td {
    padding:10px;
    box-sizing:border-box;
    background-color : #f5f1e8;
}

.add_but{
    background: #202738;
    border-radius: 11px;
    padding: 8px 23px;
    color: #fff;
    border: 0px;
    margin-top: 20px;
   
}

.common_button {
    background: #242d42;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;
    line-height: 27px;
    color: #FFFFFF;
    white-space: nowrap;
}