/* section {
	position: relative;
	width: 100%;
	height: 100vh;
	background: #f7f7f7;
	font-family: Roboto;
} */
section .img-box {
	position: relative;
	width: 50%;
}

section .img-box:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background: linear-gradient(225deg, #e91e63, #ffd072); */
	z-index: 1;
	mix-blend-mode: screen;
}

section .img-box img {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	object-fit: cover;
}

.login-errors {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 27px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: #B00020;
}
.input-box .fa-eye-slash  {
	color: #606734;
    font-size: 20px;
    position: absolute;
    right: 1rem;
    margin-top: 18px;
}
.input-box .fa-eye  {
	color: #606734;
    font-size: 20px;
    position: absolute;
    right: 1rem;
    margin-top: 18px;
}


.form-pd {
	padding: 10px 80px 10px 80px;
}

/* section .content-box {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: 100%;
} */
section .content-box .brand-img {
	padding-bottom: 0rem;
	width: 10%;
	padding-top: 2rem;
}

.contact-us {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 150%;
	letter-spacing: 0.25px;
	color: #323232;
}

.logo {
	text-align: center;
}

.login-section {
	background: #F5F1E8;
	height: 100vh;
}

section .content-box .form-box {
	width: 40%;
	/* height: 450px; */
	margin: 0 auto;
	background: #FFFFFF;
	border-radius: 45px;
}

form p {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	text-align: center;
	letter-spacing: 0.25px;
	color: #000000;
}

.login-card {
	padding-top: 85px;
}

section .content-box .form-box h2 {
	color: black;
	font-weight: 700;
	font-size: 2.5em;
	text-transform: capitalize;
	margin-bottom: 20px;
	/* border-bottom: 4px solid #ff4584; */
	display: inline-block;
	letter-spacing: 1px;
}

section .content-box .form-box .input-box {
	margin-bottom: 15px;
	/* margin: 1rem 0; */
}

section .content-box .form-box .input-box span {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 150%;
	text-align: center;
	letter-spacing: 0.25px;
	color: #323232;
}

section .content-box .form-box .input-box input {
	width: 100%;
	padding: 15px 20px;
	outline: none;
	font-family: Roboto;
	font-weight: 400;
	border: 1px solid #c6c6c6;
	font-size: 16px;
	letter-spacing: 1px;
	color: #c6c6c6;
	background: #fff;
	border: 2px solid rgba(196, 107, 72, 0.2);
	border-radius: 15px;
	font-family: poppins;
}

.contact-us a {
	color: #202738;
	text-decoration: none;
}

.login-bottom-border {
	border: 2px solid rgba(196, 107, 72, 0.2);
	width: 72%;
	margin-top: 10px !important;
	margin-left: 2.5rem !important;
}

.send-email {
	width: 40%;
	height: 450px;
	margin: 0 auto;
	background: #202738;
	border-radius: 45px;
	padding: 100px 62px 10px 62px;
	;
}

.send-email h6 {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 150%;
	text-align: center;
	letter-spacing: 0.25px;
	color: #FFFFFF;
}

.send-email p {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 170%;
	text-align: center;
	letter-spacing: 0.25px;
	text-decoration-line: underline;
	color: #FFFFFF;
}

.login-btn {
	background: #202738;
	border-radius: 15px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 150%;
	letter-spacing: 0.25px;
	color: #FFFFFF;
	width: 98%;
	margin-top: 10px;
}

section .content-box .input-box input::placeholder {
	font-weight: 400;
}

section .content-box .input-box input[type='submit'] {
	background: #FF8800;
	color: #fff;
	outline: none;
	border: none;
	font-weight: 500;
	cursor: pointer;
	text-transform: uppercase;
}

section .content-box .input-box input[type='submit']:hover {
	background: #FF8800;
}

section .content-box .remember {
	margin-bottom: 50px;
	color: #000;
	text-align: center;
	font-weight: 400;
	font-size: 16px;
}

section .content-box .remember .checkbox {
	margin-right: .45rem;
}

section .content-box .form-box .remember .forget-password a {
	text-decoration: none;
	color: #FF8800;
}

@media (max-width: 980px) {
	section .img-box {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	section .content-box {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	section .content-box .brand-img {
		padding-bottom: 2rem;
	}

	section .content-box .form-box {
		width: 100%;
		padding: 40px;
		background: rgba(255, 255, 255, .85);
		margin: 50px;
	}
}

@media (max-width: 480px) {
	section .content-box .brand-img {
		padding-bottom: 2rem;
	}

	section .content-box .form-box {
		margin: 10px;
		padding: 20px;
	}
}