.dish-list-bg {
    background: #F5F1E8;
    margin: 20px 20px 0px 20px;
    text-align: center;
    padding: 8px;
}

.dish-list-bg p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: #323232;
}
.done-btn {
    margin-top: 3rem;
    margin-bottom: 2rem;
}
.dish-list-item-bg {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #FFFFFF;
    margin: 5px;
}
.list-card-bg-color {
    background: rgb(245 245 245 / 67%);
    margin: 0px 20px 0px 20px;
    padding: 10px;
    overflow-x: hidden;
    height: 507px;
}
.list-card-bg-color::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .list-card-bg-color::-webkit-scrollbar-track {
    background: rgba(229, 209, 191, 0.3);
    border-radius: 20px;
  }
   
  /* Handle */
  .list-card-bg-color::-webkit-scrollbar-thumb {
    background: #202738; 
    border-radius: 10px;
  }
.dish-search-box {
    padding-top: 7px !important;
    height: 50px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    background: #FFFFFF;
    border-radius: 15px;
}

.info-modal .modal-dialog {
    max-width: 690px;
    margin: 0rem;
    position: absolute;
    right: 0px;
    width: 100%;
    top: 0px;
    border-radius: 0px;
}
.info-modal .modal-content {
height: 100vh;
border-radius: 0px !important;
}
.info-modal .modal-header{
    border-bottom: 0px;
    background: #F5F1E8;
}
.link_details{
    padding: 6rem 0rem;
    text-align: center;
    display: block;
    color: black;
    text-decoration: none;
    font-weight: 600;
}
.list-card-bg-color{

}