.dish-body td{
    font-family: poppins;
    width: 54px;
    padding-top: 17px;
}

.dish-body tr:hover{
  background: rgb(50 50 50 / 13%);
  border-radius: 8px;
}
.dish-body {
  padding: 0px 0px 0px 23px;
  margin-top: 40px;
  height: 550px;
  overflow: auto;
  max-width: 98%;

}

.dish-body .table>thead {
    background: #F5F1E8;
    border-radius: 8px;
    height: 40px;
}
.dish-body th{
  padding-bottom: 7px;
  padding-left: 10px;
}

.dish-body .table>:not(:first-child) {
    border-top: none !important;
}

.dish-body .table>:not(caption)>*>* {
    border-bottom: none;
}
.dish-modal {
    justify-content: space-around !important;
}
.input_bottom label{
     margin-top:20px;
}
.input_bottom textarea{
  height: 44px;
  border-radius: 15px;
}
.cancel_but{
    border: 1px solid #202738;
border-radius: 15px;
color: #202738;
background-color: transparent;
padding: 8px 63px;
font-family: 'Poppins';
font-weight: 600;
font-size: 16px;
margin-top: 30px;
}
.save_but{
    border: 1px solid #202738;
border-radius: 15px;
color: #fff;
background: #202738;
padding: 8px 63px;
font-family: 'Poppins';
font-weight: 600;
font-size: 16px;
margin-top: 30px;
}
.upload_box{
    background: #FFFFFF;
    border: 1px solid rgba(50, 50, 50, 0.2);
    border-radius: 15px; 
    height: 147px;
    margin-bottom: 25px;
}
.upload_box h6 {
    /* width: 136px; */
    font-size: 12px;
    height: 100px;
    padding-top: 20px;
    padding-left: 20px;
    margin-right: 20px; 
    text-align: center;
    display: flex;
    justify-content: space-between;
}
.image_box{
    border: 1px solid rgba(50, 50, 50, 0.2);
    border-radius: 15px;
    height: 100px;
   padding-top: 37px;
    width: 136px;
}
.image_box_browse{
    border: 1px solid rgba(50, 50, 50, 0.2);
    border-radius: 15px;
    height: 100px;
    width: 136px;
    padding-top: 37px;
}
.upload_inside_box {
    /* display: flex;
    justify-content: flex-end; */
}
.input_description input{
    height: 156px;
    border-radius: 15px;
}


/* checkbox */
.checkbox_custom {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox_custom input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 8px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid rgba(50, 50, 50, 0.2);
border-radius: 4px;
    /* background-color: #eee; */
  }
  
  /* On mouse-over, add a grey background color */
  /* .checkbox_custom:hover input ~ .checkmark {
    background-color: #ccc;
  } */
  
  /* When the checkbox is checked, add a blue background */
  .checkbox_custom input:checked ~ .checkmark {
    background-color: #202738;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox_custom input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox_custom .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .option_checkbox p{
   font-family: 'Poppins';
   font-weight: 400 !important;
   font-size: 15px !important;
   width: 297px;
    word-break: break-all;
  }
  .grid_div{
    display: grid;
    grid-template-columns: auto auto;
  }
  .option_checkbox h5{
    margin-top: 30px;
      font-weight: 400;
      font-size: 15px;
  }
  .tag_header{
    background: #F5F1E8;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 12px;
  }
  .tag_box{
    margin-top: 40px;
  }
.tag_box_bottom{
  background: #d7d7d773;
    height: auto;
    display: grid;
    grid-template-columns: auto auto auto;
}
.food_button{
  background: #FFFFFF;
border: 1px solid rgba(50, 50, 50, 0.2);
border-radius: 15px;
padding: 7px 30px;
height: 44px;
    margin: 25px 7px;
}
.close_tag{
  position: absolute;
  top: -9px;
  right: -12px;
}
.close_tag img{
  width: 30px;
}
.input-search {
	padding-top: 7px !important;
	height: 50px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 16px;
	background: transparent !important;
  border: 1px solid rgba(196, 107, 72, 0.2);
  border-radius: 15px;
  width: 100%
}
.input-search-box{
  padding-top: 7px !important;
	height: 50px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 16px;
	background: transparent !important;
  border: 1px solid rgba(196, 107, 72, 0.2);
  border-radius: 15px;
}
.fa_search {
	position: absolute;
	right: 1rem;
	top: 15px;
	font-size: 20px;
	color: #606734;
}
  .right_side_section{
    width: 75%;
    float: right;
  }
  .dish_switch{
    position: absolute;
    right: 15px;
    top: -12px;
  }
  .dis{
    margin-top: 52px;
  }
  .image_upload_box{
    border: 1px solid rgba(50, 50, 50, 0.2);
    border-radius: 15px;
    height: 156px;
    /* display: flex; */
    /* justify-content: space-between; */
  }
  .up_box{
    border-radius: 15px;
    position: relative;
  }
  .up_box img{
    width: 116px;
    height: 116px;
    border-radius: 15px;
  }
  .fa-asterisk{
    font-size: 8px;
    position: relative;
    top: -5px;
    color: #df3838;
  }

  .add_modal .modal-dialog {
    max-width: 800px;
    margin: 0rem;
    position: absolute;
    right: 0px;
    width: 100%;
    top: 0px;
    border-radius: 0px;
}
  .add_modal .modal-content {
    height: 750px;
    border-radius: 0px !important;
    }
    .add_modal .modal-body{
      background-color: #FFFFFF;
      height: auto;
    }
    .add_modal .modal-header{
        border-bottom: 0px;
        background: #F5F1E8;
    }
    .add-header{
      position: absolute;
    top: -36px;
    }
    .tags_modal{
      background-color: rgba(0, 0, 0, 0.555);
    max-width: 52%;
    position: fixed;
    right: 0px !important;
    left: 46.8%;
    top: 48px;
    }
    @media (min-resolution: 130dpi) and (max-resolution: 150dpi){
      .tags_modal{
      max-width: 57%;
      position: fixed;
      right: 0px !important;
      left: 41.6%;
      top: 48px;
      }
    }
    @media (min-resolution: 150dpi) and (max-resolution: 160dpi){
      .tags_modal{
      max-width: 65%;
      position: fixed;
      right: 0px !important;
      left: 33.8%;
      top: 48px;
      }
    }
    @media (min-resolution: 80dpi) and (max-resolution: 110dpi){
      .add_modal .modal-content {
        height: 950px;
        border-radius: 0px !important;
        }
        .tags_modal{
          max-width: 47%;
          left: 51.8%;
        }
    }
    @media (min-resolution: 70dpi) and (max-resolution: 100dpi){
      .add_modal .modal-content {
        height: 950px;
        border-radius: 0px !important;
        }
        .tags_modal{
          max-width: 42%;
         left: 56.8%;
        }
    }