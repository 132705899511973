.error_msg {
  font-size: 12px;
  color: #ff0000bd;
}

.events-mt .fa-eye {
  position: absolute;
  bottom: 10px;
  right: 10px
}

.events-mt .fa-eye-slash {
  position: absolute;
  bottom: 10px;
  right: 10px
}

.code_box {
  display: flex;
  justify-content: space-between;
}

.code_box input {
  width: 155px;
  height: 54px;

}

.code_box select {
  width: 340px;
  border-radius: 8px;
}

.app_pass input {
  height: 54px;
}

.code_box .input_code {
  margin: 0 1rem;
  padding: 0 1rem;
}

.code_box input::-webkit-outer-spin-button.input_code ,
.code_box input::-webkit-inner-spin-button.input_code {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.code_box input[type=number].input_code {
  -moz-appearance: textfield;
}