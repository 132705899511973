.employee-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    background: #F5F1E8;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 10px 20px 0px 20px;
}

.add-employee-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.25px;
    background: rgba(50, 50, 50, 0.2);
    border-radius: 15px;
    color: #323232;
    height: 36px;
    border: none;
    padding: 0px 20px 0px 20px;
}

.employee-section p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #323232;
}

.entries {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px 20px 10px 20px;
}

.employee-list-bg {
    background: rgba(229, 209, 191, 0.1);
    border-radius: 10px;
}

.entries-dropdowm {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #323232;
}

.previous-dropdown label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #323232;
    opacity: 0.38;
}

.next {
    float: right;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #323232;
    margin: 0px;
    padding-left: 10px;
}

.employee-pd {
    margin-left: 10px;
    padding: 2px;
    border-radius: 5px;
}

.employee-list-head {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}

.employee-list {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}

.list-number {
    position: relative;
    right: 70px;
}

.table>tbody>tr>td,
.table>tbody>tr>th {
    border-top: 0;
}

.table>thead>tr>th {
    border-bottom: 0px;
}

.employee-table {
    margin-left: 13px;
}

.table-border-bottom {
    border-bottom: 1px solid rgba(196, 107, 72, 0.2);
}

.add-employee-bg {
    background: #F5F1E8;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-top: 15px;
}
.add-employee-validation {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 36px;
    color: #f10909 !important;
    margin-bottom: -1rem;
    text-align: left;
}
.add-employee-bg h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #323232;
    padding: 10px;
}

.employee-form-section p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #323232;
}

.employee-form-section {
    background: rgba(229, 209, 191, 0.1);
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
}

.form-mt {
    margin-top: 20px;
}

.form-inputs {
    display: flex;
    justify-content: space-between;
}

.employee-form label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: #323232;
}

.employee-form input {
    border: 1px solid rgba(196, 107, 72, 0.2);
    border-radius: 8px;
    cursor: pointer;
}

.employee-form input:focus {
    border: 1px solid rgba(196, 107, 72, 0.2);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.view-employee {
    background: rgba(196, 196, 196, 0.1);
    border-radius: 10px;
    /* padding: 10px 440px 50px 10px; */
    margin-top: 35px;
}

.employee-part {
    width: 75%;
    float: right;
    position: relative;
    padding: 0px 15px 10px 15px;
}

.employee-role {
    border: 1px solid rgba(196, 107, 72, 0.2);
    border-radius: 8px;
    padding: 5px;
}

.employee-card {
    background: rgba(248, 230, 194, 0.3);
    border-radius: 10px;
    padding: 20px 10px 17px 10px;
    margin: 0px 0px 60px 15px !important;
}

.employee-details {
    display: flex;
    justify-content: space-between;
}

.employee-btn {
    background: #202738;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 10px 10px 10px -10px;
    padding: 2px 20px 2px 20px;
}

.employee-img {
    margin-left: 17px;
    width: 50%;
}
.employee-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #323232;
    padding: 15px;
}

.super-admin-icon {
    width: 13%;
    margin-right: 5px;
}

.right-border {
    border-right: 1px solid rgba(196, 107, 72, 0.2);
}

.user-information p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: rgba(50, 50, 50, 0.8);
}
.user-information span {
    color: rgba(50, 50, 50, 0.8);
    font-weight: 500;
}
.employee-header{
    float: right;
    width: 76%;
    background: #FFFFFF;
    box-shadow: 2px 4px 8px rgb(0 0 0 / 10%);
    padding: 20px; 
}