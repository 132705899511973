.side_bar {
    width: 25%;
    position: fixed;
}

.side-bar-bg {
    background-image: url("https://d3eavn3p0apacm.cloudfront.net/windmill-images/sidebar_BG.svg");
    background-size: cover;
    background-position: bottom;
   
    height: 95vh;
}

.content_right_side {
    width: 74%;
    float: right;
    margin-right: 20px;
}

.user-icon-img {
    display: flex;
    justify-content: space-between;
    padding: 15px 23px 20px 25px;
    align-items: center;
}

.sidebar-section {
    width: 25%;
}

.user-icon-img p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 19.875px;
    line-height: 30px;
    color: #202738;
    margin-left: 0px;
    margin-bottom: 0rem !important;
}
/* .windmill-logo {
 background-image: url("https://d3eavn3p0apacm.cloudfront.net/windmill-images/shape_sidebar.svg"); 
 height: 190px;
 background-size: cover;
 background-position: 100%;
 position: relative;
 top: -45px;
 z-index: 11;
} */
.windmill-logo h4{
    text-align: center;
    font-family: poppins;
    color: #fff;
    /* padding-top: 6.5rem; */
    font-weight: 600;
    font-size: 30px;
}
.windmill-logo{
    position: absolute;
    bottom: 5%;
    left:25%;
    right: 25%;
    
}
.sidebar-links {
    padding: 20px 20px 20px 25px;
}

.sidebar-links p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: rgba(50, 50, 50, 0.8);
}

.user-icon-img .btn:active:focus,
.btn:focus {
    outline: none !important;
    box-shadow: none;
}

.user-icon-img .btn {
    background: #F5F1E8 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 30px;
    color: #323232;
    border: 0px;
}
.show>.btn-primary.dropdown-toggle{
color: #000 !important;
}
.show>.btn-primary.dropdown-toggle:focus{
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 0%) !important;  
}
#dropdown-basic{
    padding: 0.34rem 0rem !important;
}
.selected_item{
    padding: 0px 10px !important;
}
.selected_item p{
    font-size: 16px !important;
}
.sidebar-links a {
    text-decoration: none !important;
    color: rgba(50, 50, 50, 0.8);
}

.sidebar-links ul li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: rgba(50, 50, 50, 0.8);
    list-style-type: none;
    padding: 8px 10px 17px 3px;
}
.sidebar-links ul {
    padding-left: 0rem !important;
}
.sideBarActive {
    font-weight: 600 !important;
    line-height: 30px;
    color: #202738 !important;
}

.active {
    font-weight: 600 !important;
    line-height: 30px;
    color: #202738 !important;
}

.active .border_left {
    height: 16px;
    border-color: black;
    position: absolute;
    border-left: 2px solid black;
    left: -5px;
    top: 15px;
}

.user-icon-img .dropdown-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: rgba(50, 50, 50, 0.67);
}

.user-icon-img a {
    text-decoration: none !important;
}

@media (min-resolution: 130dpi) and (max-resolution: 150dpi){
    /* .side-bar-bg {
        height: 85vh;
    }
    .windmill-logo {
        top: -93px; 
    } */
    .sidebar_toggle ul li{
        line-height: 30px;
        
    }
    .sidebar-links ul li{
        padding: 8px 10px 9px 3px;
    }
    .sidebar-links a {
        font-size: 17px;
    }
    .sidebar_toggle ul li a{
    font-size: 18px;
    
    }
    .sidebar_toggle{
     height: 84vh !important;
    }
}
.drodown_but{
    border: 0px;
}
.sidebar_toggle{
    position: absolute;
    position: absolute;
    background: #CFC3B1;
    border-radius: 0px 0px 50px 0px;
    width: 100%;
    left: 0;
    top: 0px;
    z-index: 1;
    height: 74vh;
    padding-top: 60px;
}
.sidebar_toggle .fa-times{
    position: absolute;
    right: 0px;
    padding: 20px;
    top: 0px;
    font-size: 23px;
    cursor: pointer;
}
.sidebar_toggle ul li{
    list-style: none;
    line-height: 40px;
    
}
.sidebar_toggle ul li a{
    color: #323232;
    font-weight: 400;
font-size: 20px;

}
.sidebar_toggle ul li a:hover{
    color: #fff;
}
.dropdown-item:active {
    background-color: transparent !important;
}


/* pagination-css */
.pagination .active{
    line-height: 24px;
}
.pagination .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #202738 !important;
    border-color: #202738 !important;
}
.pagination .page-link {
    color: #000;
}
