.add-event-form {
  background: rgba(229, 209, 191, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  margin-right: 15px;
}

.add-events-section {
  margin-top: 20px;
}

.events-breadcrum {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(50, 50, 50, 0.67);
  margin-top: 10px;
}

.delete-doc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
  background-color: white;
  border: navajowhite;
  padding: 0;
  margin-top: 10px
}

.delete-doc:hover {
  color : red
}

.events-breadcrum span {
  color: #323232;
}

.add-event-form p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #323232;
}

.event-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.cancel-btn {
  width: 100%;
  margin-right: 25px;
}

.cancel-btn button {
  border: 1px solid #202738;
  border-radius: 15px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.25px;
  color: #202738;
  width: 100%;
  padding: 11px;
}

.save-btn button {
  background: #202738;
  border-radius: 15px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  width: 100%;
  padding: 11px;
}

.save-btn {
  width: 100%;
}

.events-mt {
  margin-top: 20px;
}

.description {
  height: 80px !important;
}

.select-event {
  width: 100%;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 12px;
  overflow: visible !important;
  top: 6px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
}

.add-event-form input {
  border: 1px solid rgba(196, 107, 72, 0.2);
  border-radius: 8px;
  cursor: pointer;
}

.add-event-form label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #323232;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 0px;
  top: -2px;
  bottom: 4px;
  background: rgba(50, 50, 50, 0.67);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.table-header th {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #323232;
}

.table-body th {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
}

.table-body td {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
}

.table-body td a {
  font-family: 'Poppins';
  color: #323232;
  text-decoration: none !important;
}

.table-body td a:hover {
  text-decoration: none !important;
}

.event_but{
  border: 1px solid #202738;
  border-radius: 15px;
  color: #fff;
  background: #202738;
  padding: 8px 63px;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
}
.image_box{
text-align: center;
border: 1px solid rgba(50, 50, 50, 0.2);
border-radius: 15px;
width: 116px;
height: 116px;
}
.banner-im{
  width: 100%;
height: 325px;
border: 1px dashed rgba(50, 50, 50, 0.2);
border-radius: 15px;
}
.image_size {
  margin-right: 20px;
}
.image_size img{
  border-radius: 15px;
}
.img_position{
  position: absolute;
  right: 0px;
  width: 30px !important;
height: 30px !important;
  top: -7px;
}